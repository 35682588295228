import { LogoPropsType } from './logoTypes';

export default function BazelShortLogo({ monochrome = false, className }: LogoPropsType) {
  return (
    <svg
      viewBox="0.6410000324249268 0.5419999957084656 65.35899996757507 65.35800153017044"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path fill={monochrome ? 'currentColor' : '#76D275'} d="m16.98.542 16.34 16.34L16.98 33.22.642 16.88z" />
      <path fill={monochrome ? 'currentColor' : '#43A047'} d="M.641 16.88v16.34l16.34 16.34V33.22z" />
      <path fill={monochrome ? 'currentColor' : '#76D275'} d="m49.659.542 16.34 16.34-16.34 16.339-16.34-16.34z" />
      <path
        fill={monochrome ? 'currentColor' : '#43A047'}
        d="M66 16.88v16.34L49.66 49.56V33.22zM33.32 16.88l16.34 16.34-16.34 16.34-16.339-16.34z"
      />
      <path fill={monochrome ? 'currentColor' : '#00701A'} d="M33.32 49.56V65.9L16.982 49.56V33.222z" />
      <path fill={monochrome ? 'currentColor' : '#004300'} d="m33.32 49.56 16.339-16.339v16.34L33.319 65.9z" />
    </svg>
  );
}

import { LogoPropsType } from './logoTypes';

export default function MavenShortLogo({ className }: LogoPropsType) {
  return (
    <svg
      viewBox="0 0 57.99967956542969 81.17599487304688"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="url(#mavenShortLogo_svg__a)"
        d="M23.635 57.066c-.509.948-1.036 1.923-1.552 2.927-.002.014-.018.026-.02.04-.078.145-.154.276-.219.422-.354.673-.657 1.282-1.37 2.656.766.496 1.277 1.566 1.726 2.768a4.53 4.53 0 0 0-.996-3.286c3.994.77 7.62.265 9.836-2.423.19-.242.384-.498.567-.77-.973.91-2.04 1.208-3.929.817 2.98-.842 4.564-1.846 6.093-3.654.362-.43.729-.902 1.105-1.43-2.818 2.16-5.76 2.453-8.672 1.471l-2.277-.078c-.098.185-.193.355-.292.54"
      />
      <path
        fill="url(#mavenShortLogo_svg__b)"
        d="M21.344 50.108a43 43 0 0 0-1.494 5.152c-.01.056-.033.123-.04.179-.659-1.518-2.797-3.224-2.785-3.208 1.315 2.665 2.23 5.244 2.065 7.621-.917.05-2.102-.393-3.432-1.114 1.277 1.566 2.32 2.102 2.738 2.263-1.362-.114-2.904.612-4.48 1.475 2.198-.545 3.93-.632 5.085-.178-2.696 5.104-5.476 10.779-8.308 16.815.609-.081 1.016-.434 1.288-.934.501-1.092 3.872-8.271 8.852-17.616.139-.264.29-.526.431-.804.04-.08.078-.145.119-.224q.788-1.461 1.634-3c.133-.236.252-.46.386-.697l.002-.014-1.976-5.903a1 1 0 0 0-.085.187"
      />
      <path
        fill="url(#mavenShortLogo_svg__c)"
        d="M38.84 31.798c-.108.155-.2.298-.308.453-.31.466-.634.93-.945 1.41-.347.518-.695 1.05-1.058 1.593-.182.271-.365.543-.548.829-.55.843-1.105 1.714-1.677 2.61a209 209 0 0 0-2.14 3.452 275 275 0 0 0-2.126 3.54c-.683 1.164-1.383 2.354-2.092 3.6-.63 1.102-1.267 2.245-1.922 3.414-.036.051-.06.119-.096.17a471 471 0 0 0-1.96 3.578c-.018.026-.036.051-.04.08l2.276.078c-.04-.02-.081-.027-.135-.049 2.734.06 6.59-1.42 9.249-3.56 1.224-.985 2.38-2.18 3.5-3.622.84-1.07 1.643-2.274 2.453-3.633a55 55 0 0 0 2.089-3.872c-.659.23-1.404.363-2.207.415l-.43.023c-.143.007-.284 0-.441.006 2.646-.593 4.489-2.212 5.964-4.326-.719.349-1.876.776-3.2.88a6 6 0 0 1-.542.02c-.041-.006-.085.002-.141-.006.916-.235 1.712-.53 2.424-.924.154-.077.294-.17.436-.263.22-.138.427-.292.635-.447.13-.108.257-.203.386-.312.294-.256.577-.54.837-.858.086-.101.17-.188.242-.291.107-.155.214-.296.305-.439a25 25 0 0 0 1.53-2.476c.041-.093.095-.17.138-.264.121-.252.24-.476.326-.676a7 7 0 0 0 .275-.713c-.094.057-.2.112-.32.152-.817.335-2.172.562-3.244.604-.014-.002-.028-.004-.044.008-.1 0-.213-.003-.328.008.016-.011.043-.007.06-.02l-7.153-.267c.006.058-.012.084-.028.095"
      />
      <path
        fill="url(#mavenShortLogo_svg__d)"
        d="M25.414 52.197c.671-1.18 1.357-2.373 2.046-3.58a267 267 0 0 1 2.044-3.465 410 410 0 0 1 2.118-3.484 184 184 0 0 1 2.222-3.525c.739-1.157 1.505-2.309 2.254-3.45.275-.414.546-.815.834-1.227.472-.699.958-1.395 1.444-2.09.02-.04.054-.078.074-.118l-1.941-5.855c-.052.063-.106.14-.171.202-.796.963-1.607 1.939-2.394 2.946a114 114 0 0 0-2.376 3.119c-.658.898-1.301 1.798-1.947 2.713-.126.18-.255.374-.38.555a77.734 77.734 0 0 0-4.194 6.888c-.393.738-.768 1.45-1.11 2.14-.285.583-.557 1.182-.829 1.768a55 55 0 0 0-1.693 4.185l1.976 5.904c.506-.935 1.04-1.866 1.565-2.826.152-.262.304-.524.458-.8"
      />
      <path
        fill="url(#mavenShortLogo_svg__e)"
        d="M48.856 18.745c-.766.868-1.61 1.867-2.526 2.983-.053.063-.102.113-.14.178-.797.977-1.637 2.048-2.536 3.238a153 153 0 0 0-2.447 3.307c-.748 1.027-1.505 2.124-2.316 3.284l7.153.268c2.183-.646 3.226-1.36 4.299-2.467.285-.3.575-.626.866-.967a41 41 0 0 0 2.587-3.301c.788-1.107 1.505-2.21 2.063-3.208.365-.643.665-1.238.9-1.772.196-.469.362-.914.51-1.346-2.794.058-6.212.012-8.413-.197"
      />
      <path
        fill="url(#mavenShortLogo_svg__f)"
        d="M40.779 28.076a203 203 0 0 1 2.482-3.26 92 92 0 0 1 2.752-3.348 84 84 0 0 1 2.566-2.864l-1.541-4.319c-.181.172-.377.343-.575.527a96 96 0 0 0-2.268 2.183c-.882.88-1.785 1.812-2.726 2.797-.859.912-1.739 1.877-2.625 2.87-.75.856-1.503 1.726-2.26 2.624-.034.037-.052.063-.086.1l1.966 5.874c.782-1.065 1.55-2.132 2.315-3.184"
      />
      <path
        fill="url(#mavenShortLogo_svg__g)"
        d="M53.28 9.616c-1.27.524-3.504 2.186-6.228 4.672l1.54 4.332c1.802-1.924 3.614-3.619 5.385-5.036.13-.109.21-.168.21-.168-.066.061-.143.107-.21.168-.58.469-2.34 2.002-5.12 5.16 2.201.21 5.619.256 8.429.186 1.491-4.443.183-6.766.183-6.766s-1.57-3.628-4.188-2.548"
      />
      <path
        fill="url(#mavenShortLogo_svg__h)"
        d="M15.936 56.616c.339 1.022.683 2.074 1.057 3.14.008.011.005.031.014.042.052.157.095.302.157.45.244.72.478 1.358.984 2.82.887-.214 2.015.152 3.196.653a4.53 4.53 0 0 0-3.07-1.538c3.308-2.368 5.437-5.348 5.008-8.804a12 12 0 0 0-.168-.94c-.009 1.33-.526 2.31-2.11 3.411 1.44-2.741 1.8-4.58 1.541-6.935a23 23 0 0 0-.278-1.785c-.371 3.53-2.183 5.867-4.898 7.304l-1.624 1.599c.067.198.125.385.19.583"
      />
      <path
        fill="url(#mavenShortLogo_svg__i)"
        d="M9.309 53.49a43 43 0 0 0 2.71 4.63c.036.045.067.109.102.153-1.554-.567-4.263-.19-4.243-.187 2.839.88 5.34 1.99 6.952 3.745-.594.7-1.732 1.255-3.17 1.724 2.014.15 3.123-.237 3.527-.43-1.021.91-1.554 2.528-2.014 4.266 1.118-1.97 2.247-3.286 3.37-3.813 1.85 5.468 4.057 11.39 6.488 17.598.36-.498.384-1.035.209-1.577-.448-1.115-3.34-8.5-6.695-18.544-.096-.282-.181-.572-.287-.865-.03-.084-.05-.157-.081-.24a636 636 0 0 1-1.053-3.25c-.08-.26-.161-.5-.24-.76l-.01-.011L9.232 53.3c.022.073.045.126.078.19"
      />
      <path
        fill="url(#mavenShortLogo_svg__j)"
        d="M13.626 51.975q-.59-1.952-1.19-3.948a273 273 0 0 1-1.11-3.867c-.365-1.306-.718-2.62-1.072-3.934-.36-1.344-.7-2.687-1.028-4.038-.332-1.331-.642-2.68-.953-4.009a62 62 0 0 1-.318-1.45c-.18-.822-.352-1.654-.523-2.485-.015-.042-.02-.093-.034-.135l-5.585-2.62c.01.081.03.174.028.264.153 1.24.303 2.499.492 3.763.187 1.284.394 2.57.628 3.87.2 1.095.41 2.182.63 3.28.044.215.097.442.14.658a78 78 0 0 0 2.114 7.782c.266.793.526 1.555.79 2.277.228.608.475 1.219.714 1.818.571 1.41 1.2 2.777 1.871 4.109l5.644 2.628c-.33-1.011-.637-2.04-.974-3.08-.084-.291-.17-.582-.264-.883"
      />
      <path
        fill="url(#mavenShortLogo_svg__k)"
        d="M8.06 28.199c.037.184.078.35.116.534.125.546.238 1.1.372 1.657.138.607.284 1.225.428 1.863.072.32.144.638.225.968.233.98.484 1.98.742 3.013.318 1.27.662 2.573 1.032 3.928.346 1.284.718 2.62 1.106 3.978.376 1.296.757 2.624 1.175 3.995.364 1.215.756 2.464 1.154 3.743.013.062.045.126.058.187.4 1.26.814 2.561 1.248 3.884.005.031.012.062.029.084l1.624-1.598c-.042.015-.076.041-.128.065 1.924-1.943 3.503-5.76 3.78-9.16.127-1.568.055-3.228-.22-5.034-.2-1.345-.522-2.755-.95-4.279a55 55 0 0 0-1.373-4.18c-.287.637-.702 1.269-1.217 1.888l-.28.327c-.093.109-.194.207-.299.325 1.39-2.327 1.484-4.78.964-7.305a9.2 9.2 0 0 1-1.561 2.927 6 6 0 0 1-.36.408c-.033.026-.058.063-.102.098.46-.826.794-1.607.997-2.394.05-.164.08-.33.11-.497.05-.255.082-.511.112-.768.01-.17.03-.327.04-.496a7 7 0 0 0-.048-1.197c-.014-.132-.02-.253-.045-.376-.038-.185-.067-.359-.108-.524a25 25 0 0 0-.745-2.812c-.038-.095-.057-.187-.097-.282-.098-.262-.18-.502-.265-.703a7 7 0 0 0-.329-.69 1.5 1.5 0 0 1-.11.337c-.319.823-1.086 1.963-1.794 2.77-.01.008-.021.017-.023.037-.07.072-.149.152-.22.244.002-.02.024-.037.027-.057L8.01 28.112c.046.036.053.067.05.087"
      />
      <path
        fill="url(#mavenShortLogo_svg__l)"
        d="M5.476 11.948c.103 1.154.248 2.453.427 3.886.01.082.011.152.032.225.161 1.25.362 2.596.606 4.067.21 1.267.439 2.606.717 4.051.232 1.25.506 2.554.79 3.94l5.116-5.006c1.034-2.029 1.233-3.277 1.167-4.817A23 23 0 0 0 14.225 17a41 41 0 0 0-.616-4.149c-.26-1.333-.568-2.611-.908-3.703a20 20 0 0 0-.667-1.872c-.205-.465-.414-.891-.627-1.297-1.88 2.068-4.265 4.517-5.93 5.97"
      />
      <path
        fill="url(#mavenShortLogo_svg__m)"
        d="M6.69 24.23q-.353-2.055-.656-4.044a93 93 0 0 1-.851-8.132L.989 10.2c0 .25-.01.51-.013.78A91 91 0 0 0 1 14.127c.032 1.245.087 2.543.154 3.903.071 1.25.166 2.553.277 3.878.105 1.134.218 2.28.349 3.446.004.051.01.082.014.132l5.616 2.615c-.235-1.3-.482-2.591-.72-3.871"
      />
      <path
        fill="url(#mavenShortLogo_svg__n)"
        d="M1.896 2.458C1.402 3.741 1.071 6.505 1 10.192l4.204 1.864c-.156-2.632-.14-5.113.05-7.373.01-.17.023-.268.023-.268-.001.09-.022.178-.023.268-.058.744-.157 3.075.223 7.266 1.666-1.453 4.051-3.901 5.934-5.99-2.199-4.137-4.785-4.787-4.785-4.787S2.912-.184 1.896 2.458"
      />
      <defs>
        <linearGradient
          id="mavenShortLogo_svg__a"
          x1={5.707}
          x2={56.848}
          y1={106.06}
          y2={19.495}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset={0.095} stopColor="#662E8D" />
          <stop offset={0.788} stopColor="#9F2064" />
          <stop offset={0.949} stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__b"
          x1={10.029}
          x2={31.95}
          y1={72.032}
          y2={38.719}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset={0.095} stopColor="#662E8D" />
          <stop offset={0.788} stopColor="#9F2064" />
          <stop offset={0.949} stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__c"
          x1={11.519}
          x2={42.237}
          y1={83.962}
          y2={5.279}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__d"
          x1={6.128}
          x2={47.839}
          y1={76.092}
          y2={12.701}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__e"
          x1={9.522}
          x2={44.288}
          y1={96.816}
          y2={14.752}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__f"
          x1={4.376}
          x2={46.087}
          y1={80.04}
          y2={16.649}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__g"
          x1={48.797}
          x2={65.034}
          y1={15.039}
          y2={12.991}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69923" />
          <stop offset={0.312} stopColor="#F79A23" />
          <stop offset={0.838} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__h"
          x1={4.689}
          x2={72.6}
          y1={112.604}
          y2={49.563}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset={0.095} stopColor="#662E8D" />
          <stop offset={0.788} stopColor="#9F2064" />
          <stop offset={0.949} stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__i"
          x1={17.436}
          x2={8.345}
          y1={76.787}
          y2={37.96}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#282662" />
          <stop offset={0.095} stopColor="#662E8D" />
          <stop offset={0.788} stopColor="#9F2064" />
          <stop offset={0.949} stopColor="#CD2032" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__j"
          x1={-13.611}
          x2={18.37}
          y1={81.593}
          y2={-0.861}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__k"
          x1={25.346}
          x2={8.048}
          y1={80.619}
          y2={6.736}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__l"
          x1={-16.391}
          x2={42.939}
          y1={109.097}
          y2={36.951}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__m"
          x1={19.359}
          x2={2.06}
          y1={86.397}
          y2={12.515}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.323} stopColor="#9E2064" />
          <stop offset={0.63} stopColor="#C92037" />
          <stop offset={0.751} stopColor="#CD2335" />
          <stop offset={1} stopColor="#E97826" />
        </linearGradient>
        <linearGradient
          id="mavenShortLogo_svg__n"
          x1={2.747}
          x2={12.431}
          y1={9.443}
          y2={-3.749}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F69923" />
          <stop offset={0.312} stopColor="#F79A23" />
          <stop offset={0.838} stopColor="#E97826" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import { LogoPropsType } from './logoTypes';

export default function SbtShortLogo({ monochrome = false, className }: LogoPropsType) {
  return (
    <svg
      viewBox="7 25.898000717163086 86 47.89556884765625"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill={monochrome ? 'currentColor' : '#37110E'}>
        <path d="M11.637 61.414 7 65.61s2.27 2.847 5.29 4.54c3.021 1.712 4.676 1.578 8.062 1.54 3.252 0 6.58-.616 9.235-2.983 2.674-2.366 3.174-3.001 3.155-5.983 0-1.405-.384-4.483-2.058-6.465-1.905-2.25-5.176-3.348-5.176-3.348s-9.966-2.5-8.946-5.964c.423-1.462 2.732-2 5.002-1.597 2.867.5 5.753 2.232 5.753 2.232l4.04-3.963s-1.847-2.174-4.714-3.56c-2.116-1.038-5.04-1.423-6.907-1.423-4.405-.02-9.908 3.906-10.562 7.984-.48 2.963.481 5.483 2.674 7.388 2.752 2.405 4.714 2.713 4.714 2.713s8.792 2.27 8.792 5.002-2.135 3.733-5.54 3.79c-3.406.058-8.177-4.098-8.177-4.098M74.954 40.097l.076 6.368h3.637l.038 18.836s.635 2.174 2.27 3.77c2.117 2.078 4.33 2.598 4.33 2.598h7.137l.039-6.484-3.848-.038s-.847.038-1.79-.866c-.98-.943-.942-2.174-.942-2.174l.077-15.7h6.984L93 40.04l-6.984-.038-.077-12.313h-7.272l.077 12.37zM60.312 41.617c-4.386-.962-8.83-.481-11.659 1.174l3.713-15.084-7.407-1.809L34.435 68.36l7.35 2.116 1.02-4.156c2.154 4.08 4.617 6.042 9.33 7.1 8.735 1.981 16.546-4.118 18.643-13.526 1.77-8.06-3.732-16.796-10.466-18.277m2.136 23.51-10.543 2.906-7.87-7.965 2.675-10.87 10.543-2.906 7.87 7.965z" />
      </g>
    </svg>
  );
}
